import { initCookieBanner } from './components/initCookieBanner';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { scrollLocker } from './tools/scrollLocker';
import { initAnimationsOnScroll } from './components/initAnimationsOnScroll';
import { initStickyTableHeader } from './components/initStickyTableHeader';
import { initSliders } from './components/initSliders';
import { initMobileFeatureMenu } from './components/initMobileFeatureMenu';
import { initRenderPricesPage } from './components/initRenderPricesPage';
import { initMobileMenu } from './components/initMobileMenu';
import { initBannersTimer } from './components/initBannersTimer';
import { initAccordion } from './components/accordion';
import { initForms } from './components/initForms';
import { initJivosite } from './components/initJivosite';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initChunkPageBeforeCheckout } from './components/initChunkPageBeforeCheckout';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

document.addEventListener('DOMContentLoaded', () => {
    initPageLoader();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initChunkPageBeforeCheckout();
    initDetectionOrganicTraffic();
    initCookieBanner();
    scrollLocker();
    initLazyLoadFonts();
    initAnimationsOnScroll();
    initStickyTableHeader();
    initForms();
    initAccordion();
    initJivosite();
    initMobileFeatureMenu();
    initMobileMenu();
    initRenderPricesPage();
    initSliders();
    initBannersTimer();
    initHashEmail();
});

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});
